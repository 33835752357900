<template>
  <div>
    <flex column class="filter-container align-center" v-if="loading">
      <svg v-bind="{ viewBox, style }">
        <circle class="spinner" v-bind="spinner" fill="none" stroke-width="1" />
        <circle class="dot" v-bind="circle" />
      </svg>
    </flex>
    <slot v-else />
  </div>
</template>

<script>
export default {

  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    viewBox() {
      return this.small ? '0 0 20 20' : '0 0 50 50'
    },

    spinner() {
      return this.small
        ? { cx: 10, cy: 10, r: 8 }
        : { cx: 25, cy: 25, r: 20 }
    },

    circle() {
      return this.small
        ? { cx: 10, cy: 10, r: 2 }
        : { cx: 25, cy: 25, r: 2 }
    },

    style() {
      return this.small
        ? { width: '20px', height: '20px' }
        : { width: '48px', height: '48px' }
    }
  }

}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";
@import "../../scss/theme/variables";

.filter-container {
  filter: drop-shadow(0 0 0.35rem rgba($primary, 0.75));
}

svg {
  animation: rotate 2s linear infinite;
  z-index: 2;

  circle.spinner {
    stroke: $primary;
    stroke-linecap: round;
    animation: dash 1.5s cubic-bezier(0.45, 0, 0.55, 1) infinite;
  }

  circle.dot {
    fill: $primary;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
</style>
